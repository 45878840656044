import { PaginatedDocs } from 'payload'
import { Knowledge } from '@/payload-types'

export default async function fetchKnowledges(): Promise<
  PaginatedDocs<Knowledge>
> {
  const response = await fetch(
    process.env.NEXT_PUBLIC_BASE_URL +
      `/api/knowledge?sort=sort&where[_status][equals]=published`,
    {
      method: 'GET',
      credentials: 'include',
    },
  )

  if (!response.ok) {
    throw new Error('Failed to fetch knowledges')
  }

  return response.json()
}
