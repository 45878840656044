import { PaginatedDocs } from 'payload'
import { Project } from '@/payload-types'

export default async function fetchProjects(): Promise<PaginatedDocs<Project>> {
  const response = await fetch(
    process.env.NEXT_PUBLIC_BASE_URL + `/api/projects`,
    {
      method: 'GET',
      credentials: 'include',
    },
  )

  if (!response.ok) {
    throw new Error('Failed to fetch projects')
  }

  return response.json()
}
