import { Main } from '@/payload-types'

export default async function fetchMain(): Promise<Main> {
  const response = await fetch(
    process.env.NEXT_PUBLIC_BASE_URL + `/api/globals/main`,
    {
      method: 'GET',
      credentials: 'include',
    },
  )

  if (!response.ok) {
    throw new Error('Failed to fetch Main info')
  }

  return response.json()
}
