'use client'

import React from 'react'
import { Title } from '@/components/Typography/Title'
import { Container } from '@/components/Layout/Container'
import Card from '@/components/Layout/Card'
import Subtitle from '@/components/Typography/Subtitle'
import Content from '@/components/Layout/Content'
import Chip from '@/components/Layout/Chip'
import { useQuery } from '@tanstack/react-query'
import fetchKnowledges from '@/client/knowledges'

export function Knowledges() {
  const { data: knowledges } = useQuery({
    queryKey: ['knowledges'],
    queryFn: () => fetchKnowledges(),
  })

  return (
    <Container id={'knowledge'}>
      <Title>Technologien und Kenntnisse</Title>
      <Content className='space-y-6'>
        {knowledges &&
          knowledges.docs.map((knowledge) => (
            <Card key={knowledge.id} className='flex flex-col xl:flex-row'>
              <Subtitle className='mb-2 text-lg xl:mb-0 xl:w-1/4'>
                {knowledge.title}
              </Subtitle>
              <dl className='flex flex-wrap items-center gap-3 border-gray-200 xl:w-3/4 xl:border-l xl:pl-4 dark:border-gray-600'>
                {knowledge.knowledgeList.map((knowledgeElement) => (
                  <Chip key={knowledgeElement.id}>
                    <dt>{knowledgeElement.name}</dt>
                  </Chip>
                ))}
              </dl>
            </Card>
          ))}
      </Content>
    </Container>
  )
}
